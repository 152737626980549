import { axiosInstance } from '@/config/axios'
import { CustomError, parseErrorFromThunk } from '@/helpers/errors'
import { DetailedTrack } from '@/types/discovery/track'
import { Playlist } from '@/types/playlist'
import { SimilarCollectionsResponse } from '@aims-api/aims-node/dist/helpers/types/collection'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { z } from 'zod'
import { selectUserId } from '../auth/selectors'
import { NotificationStatus } from '../notification/slice'
import { addNotification } from '../notification/thunks'
import { RootState } from '../store'

const responseSchema = z.object({ success: z.boolean(), data: z.any() })

export const getPlaylist = createAsyncThunk<
  { collection: Playlist },
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getPlaylist', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}`)

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getPlaylistList = createAsyncThunk<
  Playlist[],
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  { sharedOnly?: boolean },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getPlaylistList', async ({ sharedOnly }, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/playlist/list', {
      params: { user_id: selectUserId(thunkApi.getState()), shared_only: sharedOnly },
    })

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data.collections
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getTracksOfPlaylist = createAsyncThunk<
  { tracks: DetailedTrack[]; pagination: { item_count: number } },
  { key: string },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getTracksOfPlaylist', async ({ key }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}/tracks`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

const SUGGESTIONS_PAGE_SIZE = 10

export const getSuggestedTracksForPlaylist = createAsyncThunk<
  { tracks: DetailedTrack[] },
  { key: string; page: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getSuggestedTracksForPlaylist', async ({ key, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(
      `/api/playlist/${key}/suggest-tracks?page=${page}&page_size=${SUGGESTIONS_PAGE_SIZE}`,
    )
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const getSimilarPlaylists = createAsyncThunk<
  SimilarCollectionsResponse,
  { key: string; page: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/getSimilarPlaylists', async ({ key, page }, thunkApi) => {
  try {
    const response = await axiosInstance.get(`/api/playlist/${key}/similar?page=${page}`)
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }
    return parserResponse.data.data
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const updatePlaylist = createAsyncThunk<
  Playlist,
  Pick<Playlist, 'id' | 'key' | 'title' | 'description'>,
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/updatePlaylist', async ({ id, title, description }, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/playlist/update', { id, title, description })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Playlist was updated`,
      }),
    )
    return parserResponse.data.data.collection
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const deletePlaylist = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  Playlist,
  {
    rejectValue: CustomError
    state: RootState
  }
>('playlist/deletePlaylist', async ({ id }, thunkApi) => {
  try {
    await axiosInstance.delete(`/api/playlist/delete/${id}`)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.SUCCESS,
        message: `Playlist was removed`,
      }),
    )
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
