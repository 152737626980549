import { Project } from '@/types/project'
import { RootState } from '../store'
import { DetailedTrack } from '@/types/discovery/track'
import { createSelector } from 'reselect'
import { initialTracksObject } from './slice'

export const selectProjectsList = (state: RootState) => state.project.list

export const selectProjectsLoadingStatus = (state: RootState) => state.project.listLoadingStatus

export const selectTempTrackToBeAdded = (state: RootState) => state.project.tempTrackToBeAdded

export const selectTrackActionsMenuOpen = (trackId: string) => (state: RootState) =>
  state.project.trackActionsMenuOpenForTrackId === trackId

export const selectAddTrackPopper = (state: RootState) => state.project.addTrackPopper

export const selectProjectSnapshot = (state: RootState) => state.project.snapshot

export const selectProjectPlaylist = (state: RootState) => state.project.playlist

export const selectSnapshotTracks = createSelector([selectProjectSnapshot], (snapshot) => snapshot?.tracks ?? [])

export const selectProjectDeletingStatus = (id: Project['id']) => (state: RootState) =>
  state.project.deletingStatusMap[id]

export const selectProjectTracks = (state: RootState, id: Project['id']) => {
  const tracksObject = state.project.tracksMap[id]
  if (tracksObject === undefined) {
    return initialTracksObject
  }
  return tracksObject
}

export const selectProjectSuggestions = (id: Project['id']) => (state: RootState) => state.project.suggestionsMap[id]

export const selectProjectById = (id: Project['id']) => (state: RootState) =>
  state.project.list.find((project) => project.id === id)

export const selectProjectIdByKey = (key: Project['key']) => (state: RootState) =>
  state.project.list.find((project) => project.key === key)?.id

export const selectAllProjectTracks = (state: RootState) => {
  let tracks: DetailedTrack[] = []
  state.project.list.forEach((project) => {
    const projectTracks = selectProjectTracks(state, project.id)
    tracks = tracks.concat(projectTracks.tracks)
  })
  return tracks
}

export const selectIsTrackInProject = (projectKey: Project['key'], trackIdClient: string) => (state: RootState) => {
  return state.project.list.some((project) => {
    if (project.key !== projectKey) {
      return false
    }
    const tracksObject = state.project.tracksMap[project.id]
    if (tracksObject === undefined || tracksObject.tracks === undefined) {
      return false
    }
    return tracksObject.tracks.some((track) => track.id_client === trackIdClient)
  })
}
