import { selectUserId } from '@/store/auth/selectors'
import { Store } from '@reduxjs/toolkit'
import axios from 'axios'
import Router from 'next/router'
import { z } from 'zod'

export const axiosInstance = axios.create()

const authErrorSchema = z.object({
  terminateCurrentSession: z.optional(z.boolean()),
})

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  async function (error) {
    if (error?.response !== undefined && (error.response.status === 401 || error.response.status === 403)) {
      // Prevents logging out after submitting wrong password
      if (error.config.data !== undefined) {
        const payload = JSON.parse(error.config.data)
        const parsedResponse = authErrorSchema.safeParse(payload)
        if (parsedResponse.success && parsedResponse.data.terminateCurrentSession === false) {
          return await Promise.reject(error)
        }
      }

      await Router.push('/login')
      localStorage.clear()
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    return await Promise.reject(error)
  },
)

export const setupAxiosRequestInterceptors = (store: Store) => {
  axiosInstance.interceptors.request.use(
    function (config) {
      const state = store.getState()
      const userId = selectUserId(state)

      if (userId !== undefined && userId !== null) {
        // eslint-disable-next-line no-param-reassign
        config.headers['X-User-Id'] = userId
      }

      return config
    },
    async function (error) {
      return await Promise.reject(error)
    },
  )
}
