import { createAsyncThunk } from '@reduxjs/toolkit'
import Router from 'next/router'
import { z } from 'zod'
import { axiosInstance } from '../../config/axios'
import { DEFAULT_ENABLED_FEATURES, TRIAL_FEATURES } from '../../consts/common'
import { errors } from '../../consts/errors'
import { apiDateTimeToDate } from '../../helpers/date'
import { CustomError, parseErrorFromThunk } from '../../helpers/errors'
import { ApiUserData, SubscriptionStatusResponse, subscriptionStatusResponseSchema } from '../../types/auth'
import { SubscriptionStatus } from '../../types/subscription'
import { stripeAccountData } from '../api-user/thunks'
import { RootState } from '../store'
import { selectStripeCustomerId, selectUserEmail, selectUserId } from './selectors'
import { saveRemainingRequests } from './slice'

const loginResponseSchema = z
  .object({
    id: z.string(),
    lastLoginAt: z.nullable(z.string()),
    streamingHash: z.nullable(z.string()),
    remainingRequests: z.number(),
  })
  .merge(subscriptionStatusResponseSchema)

const magicLinkLoginResponseSchema = z
  .object({
    email: z.string().email(),
  })
  .merge(loginResponseSchema)

export const magicLinkLogin = createAsyncThunk<
  ApiUserData,
  string,
  {
    rejectValue: CustomError
  }
>('auth/magicLinkLogin', async (token, thunkApi) => {
  const response = await axiosInstance.post('/api/authentication/magic-link', {
    token,
  })
  const parserResponse = magicLinkLoginResponseSchema.safeParse(response.data)
  if (!parserResponse.success) {
    return thunkApi.rejectWithValue(errors.PARSER)
  }
  let features = { ...DEFAULT_ENABLED_FEATURES, ...parserResponse.data.features }

  if (parserResponse.data.trialExpiresAt !== null) {
    const trialExpiresAtDate = apiDateTimeToDate(parserResponse.data.trialExpiresAt)
    if (trialExpiresAtDate > new Date()) {
      features = TRIAL_FEATURES
    }
  }

  return {
    ...parserResponse.data,
    features,
  }
})

export const login = createAsyncThunk<
  ApiUserData,
  { email: string; password: string; terminateCurrentSession?: boolean },
  {
    rejectValue: CustomError
  }
>('auth/login', async ({ email, password, terminateCurrentSession = true }, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/authentication/authenticate', {
      userEmail: email,
      userPassword: password,
      terminateCurrentSession,
    })
    const parserResponse = loginResponseSchema.safeParse(response.data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }

    let features = { ...DEFAULT_ENABLED_FEATURES, ...parserResponse.data.features }

    if (parserResponse.data.trialExpiresAt !== null) {
      const trialExpiresAtDate = apiDateTimeToDate(parserResponse.data.trialExpiresAt)
      if (trialExpiresAtDate > new Date()) {
        features = TRIAL_FEATURES
      }
    }

    return {
      ...parserResponse.data,
      email,
      features,
    }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const logout = createAsyncThunk<
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
  }
>('auth/logout', (_, thunkApi) => {
  try {
    const isDarkModeEnabled = localStorage.getItem('darkMode') === 'true'
    localStorage.clear()

    if (isDarkModeEnabled) {
      localStorage.setItem('darkMode', 'true')
    }
    void axiosInstance.post('/api/authentication/logout')
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const register = createAsyncThunk<
  unknown,
  { email: string; password: string; captcha: string },
  {
    rejectValue: CustomError
  }
>('auth/register', async ({ email, password, captcha }, thunkApi) => {
  try {
    await axiosInstance.post('/api/authentication/register', { email, password, captcha })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const registerConfirm = createAsyncThunk<
  unknown,
  string,
  {
    rejectValue: CustomError
  }
>('auth/registerConfirm', async (id, thunkApi) => {
  try {
    await axiosInstance.post('/api/authentication/register-confirm', { id })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const requestPasswordReset = createAsyncThunk<
  unknown,
  { email: string; captcha: string },
  {
    rejectValue: CustomError
  }
>('auth/requestPasswordReset', async ({ email, captcha }, thunkApi) => {
  try {
    await axiosInstance.post('/api/authentication/request-password-reset', { email, captcha })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const resetPassword = createAsyncThunk<
  unknown,
  { password: string; token: string },
  {
    rejectValue: CustomError
  }
>('auth/resetPassword', async ({ password, token }, thunkApi) => {
  try {
    await axiosInstance.post('/api/authentication/reset-password', { password, token })
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

const stripeCustomerResponseSchema = z.object({
  id: z.string(),
  email: z.string(),
  metadata: z.object({
    aims_autotagging_id: z.string(),
  }),
})

export const stripeCustomerId = createAsyncThunk<
  string,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
    state: RootState
  }
>('auth/stripeCustomer', async (_, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const stripeCustomerId = selectStripeCustomerId(state)

    if (stripeCustomerId !== null) {
      return stripeCustomerId
    }

    const { data } = await axiosInstance.post(`/api/stripe/customer`, {
      id: selectUserId(state),
      email: selectUserEmail(state),
    })

    const parserResponse = stripeCustomerResponseSchema.safeParse(data)
    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }

    // saves created customer's id into AIMS db
    void thunkApi.dispatch(stripeAccountData(parserResponse.data.id))

    return parserResponse.data.id
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const subscriptionStatus = createAsyncThunk<
  undefined | Pick<SubscriptionStatusResponse, 'trialExpiresAt' | 'stripeCustomerId' | 'features'>,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: CustomError
    state: RootState
  }
>('auth/subscriptionStatus', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/api-user/subscription-status')
    const parserResponse = subscriptionStatusResponseSchema.safeParse(response.data.data)

    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(errors.PARSER)
    }

    if (parserResponse.data.subscriptionStatus === SubscriptionStatus.SUSPENDED) {
      void Router.push('/settings/plans')
    }

    let features = { ...DEFAULT_ENABLED_FEATURES, ...parserResponse.data.features }

    if (parserResponse.data.trialExpiresAt !== null) {
      const trialExpiresAtDate = apiDateTimeToDate(parserResponse.data.trialExpiresAt)
      if (trialExpiresAtDate > new Date()) {
        features = TRIAL_FEATURES
      }
    }

    thunkApi.dispatch(saveRemainingRequests(parseInt(response.data.userData.remainingRequests)))

    return {
      ...parserResponse.data,
      features,
    }
  } catch (error: unknown) {
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})
