import { LoadingStatus } from '@/consts/common'
import { errors } from '@/consts/errors'
import {
  CustomError,
  SimilaritySearchAPIError,
  parseErrorFromThunk,
  parseSimilaritySeachApiError,
} from '@/helpers/errors'
import { extractCleanLink, isSupportedStreamingServiceLink } from '@/helpers/linkValidator'
import { getSegmentRangeProperty } from '@/helpers/searchSection/utils/timeDecorator'
import { ApiUserSearch, DiscoveryInputs, DiscoverySearch, LinkInfo, linkInfoSchema } from '@/types/discovery'
import { DetailedTrack } from '@/types/discovery/track'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { SearchResponse } from '@aims-api/aims-node/dist/endpoints/search'
import { TagListResponse } from '@aims-api/aims-node/dist/endpoints/tag/list'
import { AutocompleteResponse } from '@aims-api/aims-node/dist/helpers/types/search'
import { SimilarSearchDetailedResponse, TrackListDetailedResponse } from '@aims-api/aims-node/dist/helpers/types/track'
import { z } from 'zod'
import { axiosInstance } from '../../config/axios'
import { selectSiteFeatures, selectUserEmail, selectUserId } from '../auth/selectors'
import { NotificationStatus } from '../notification/slice'
import { addNotification } from '../notification/thunks'
import { closePlayer } from '../player/slice'
import { RootState } from '../store'
import {
  selectAppliedSearchParams,
  selectFiltersForAPI,
  selectLastHash,
  selectPageFetched,
  selectUserInteractedWithSegment,
} from './selectors'
import { setActiveSearch, setChipLoadingStatus } from './slice'
import * as Sentry from '@sentry/browser'

// Announcement: generic similarity search library response schema
const responseSchema = z.object({ success: z.boolean(), data: z.any() })

// TODO: create new file for new thunks, this file is very long already

export const checkFileLink = createAsyncThunk<
  string,
  string,
  {
    rejectValue: CustomError
    state: RootState
  }
>('discovery/checkFileLink', async (url, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/discovery/check-file-link', { url })
    const parserResponse = z.literal('success').safeParse(response.data)

    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(response.data)
    }

    return parserResponse.data
  } catch (error) {
    const customError = parseErrorFromThunk(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: customError.message,
      }),
    )
    return thunkApi.rejectWithValue(customError)
  }
})

export const linkInfo = createAsyncThunk<
  LinkInfo,
  string,
  {
    rejectValue: CustomError
    state: RootState
  }
>('discovery/linkInfo', async (inputValue, thunkApi) => {
  const extractedWords = extractCleanLink(inputValue).split(' ')

  if (extractedWords.length > 2) {
    return thunkApi.rejectWithValue({ message: 'Invalid link', status: 0 })
  }
  const url =
    extractedWords.length === 1
      ? extractedWords[0]
      : extractedWords[0].length > extractedWords[1].length
        ? extractedWords[0]
        : extractedWords[1]

  if (!isSupportedStreamingServiceLink(url)) {
    return thunkApi.rejectWithValue({ message: 'Invalid link', status: 0 })
  }

  thunkApi.dispatch(setChipLoadingStatus(LoadingStatus.LOADING))

  try {
    const response = await axiosInstance.post('/api/discovery/link-info', { url })
    const parserResponse = linkInfoSchema.safeParse(response.data)

    if (!parserResponse.success) {
      return thunkApi.rejectWithValue(response.data)
    }

    thunkApi.dispatch(
      setActiveSearch({
        title: parserResponse.data.title ?? url,
        link: url,
        input: DiscoveryInputs.LINK,
        linkInfo: parserResponse.data,
      }),
    )

    thunkApi.dispatch(setChipLoadingStatus(LoadingStatus.LOADED))

    return parserResponse.data
  } catch (error) {
    thunkApi.dispatch(setActiveSearch(null))
    thunkApi.dispatch(setChipLoadingStatus(LoadingStatus.ERROR))

    const customError = parseErrorFromThunk(error)
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.COULD_NOT_FETCH_LINK_INFO.message,
      }),
    )
    return thunkApi.rejectWithValue(customError)
  }
})

export const saveSearch = createAsyncThunk<
  ApiUserSearch,
  { search: DiscoverySearch; page?: number; keepFilters?: boolean },
  {
    rejectValue: CustomError
    state: RootState
  }
>('discovery/save', ({ search: newSearch, page = 1 }, thunkApi) => {
  const siteFeatures = selectSiteFeatures(thunkApi.getState())
  const isUnifiedSearchEnabled = siteFeatures?.enableUnifiedSearch ?? false

  if (page === 1) {
    thunkApi.dispatch(closePlayer())
  }
  if (newSearch.input === DiscoveryInputs.LINK && newSearch.link !== undefined) {
    void thunkApi.dispatch(searchByLink({ link: newSearch.link, page }))
  }

  if (newSearch.input === DiscoveryInputs.FILE_LINK && newSearch.link !== undefined) {
    void thunkApi.dispatch(searchByFileLink({ link: newSearch.link, page }))
  }

  if (newSearch.input === DiscoveryInputs.TEXT) {
    if (isUnifiedSearchEnabled) {
      void thunkApi.dispatch(search({ query: newSearch.title, page }))
    } else {
      void thunkApi.dispatch(searchByMetadata({ text: newSearch.title, page }))
    }
  }

  if (newSearch.input === DiscoveryInputs.TEXT_PROMPT) {
    if (isUnifiedSearchEnabled) {
      void thunkApi.dispatch(search({ query: newSearch.title, page }))
    } else if (newSearch.hash === undefined) {
      void thunkApi.dispatch(searchByText({ text: newSearch.title }))
    } else if (newSearch.hash !== undefined) {
      void thunkApi.dispatch(searchByTextHash({ hash: newSearch.hash, page }))
    }
  }

  if (newSearch.input === DiscoveryInputs.INTERNAL && newSearch.internalTrack?.id_client !== undefined) {
    void thunkApi.dispatch(searchById({ trackId: newSearch.internalTrack.id_client, page }))
  }

  if (newSearch.input === DiscoveryInputs.UPLOAD && newSearch.hash !== undefined) {
    void thunkApi.dispatch(searchByFileHash({ hash: newSearch.hash, page }))
  }

  const userId = selectUserId(thunkApi.getState())
  if (userId !== '') {
    return {
      userId,
      search: newSearch,
    }
  }
  return thunkApi.rejectWithValue({ message: 'Save did not occur', status: 400 })
})

export const searchByLink = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { link: string; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByLink', async ({ link, page = 1 }, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const filter = selectFiltersForAPI(state)
    const appliedSearchParams = selectAppliedSearchParams(state)
    const isRefined = appliedSearchParams !== null
    const hasUserInteractedWithSegment = selectUserInteractedWithSegment(state)

    const response = await axiosInstance.post('/api/discovery/search-by-link', {
      link,
      page,
      time_offset:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeOffset')
          : undefined,
      time_limit:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeLimit')
          : undefined,
      suppress_vocals: isRefined ? appliedSearchParams.queryParams.suppress_vocals : undefined,
      prioritise_bpm: isRefined ? appliedSearchParams.queryParams.prioritise_bpm : undefined,
      filter,
    })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByFileLink = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { link: string; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByFilelink', async ({ link, page = 1 }, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const filter = selectFiltersForAPI(state)
    const appliedSearchParams = selectAppliedSearchParams(state)
    const isRefined = appliedSearchParams !== null
    const hasUserInteractedWithSegment = selectUserInteractedWithSegment(state)

    const response = await axiosInstance.post('/api/discovery/search-by-file-link', {
      link,
      page,
      time_offset:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeOffset')
          : undefined,
      time_limit:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeLimit')
          : undefined,
      suppress_vocals: isRefined ? appliedSearchParams.queryParams.suppress_vocals : undefined,
      prioritise_bpm: isRefined ? appliedSearchParams.queryParams.prioritise_bpm : undefined,
      filter,
    })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByFile = createAsyncThunk<
  { data: SimilarSearchDetailedResponse; userId: string },
  FormData,
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByFile', async (data, thunkApi) => {
  try {
    const response = await axiosInstance.post('/api/discovery/search-by-audio-file', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    const userId = selectUserId(thunkApi.getState())
    return { data: parserResponse.data.data, userId }
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByFileHash = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { page?: number; hash?: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByFileHash', async ({ page, hash }, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const filter = selectFiltersForAPI(state)
    const currentHash = hash ?? selectLastHash(state)
    const lastPage = selectPageFetched(state)
    const nextPage = page === undefined ? (lastPage === undefined ? 1 : lastPage + 1) : page
    const appliedSearchParams = selectAppliedSearchParams(state)
    const isRefined = appliedSearchParams !== null
    const hasUserInteractedWithSegment = selectUserInteractedWithSegment(state)

    if (currentHash === undefined) {
      return thunkApi.rejectWithValue({ message: 'hash was not provided', code: 0 })
    }

    const response = await axiosInstance.post('/api/discovery/search-by-audio-file-hash', {
      hash: currentHash,
      page: nextPage,
      time_offset:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeOffset')
          : undefined,
      time_limit:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeLimit')
          : undefined,
      prioritise_bpm: isRefined ? appliedSearchParams.queryParams.prioritise_bpm : undefined,
      suppress_vocals: isRefined ? appliedSearchParams.queryParams.suppress_vocals : undefined,
      filter,
    })

    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByText = createAsyncThunk<
  { data: SimilarSearchDetailedResponse; userId: string },
  { text: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByText', async ({ text }, thunkApi) => {
  try {
    const filter = selectFiltersForAPI(thunkApi.getState())
    const response = await axiosInstance.post('/api/discovery/search-by-text', { text, filter })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    const userId = selectUserId(thunkApi.getState())
    return { data: parserResponse.data.data, userId }
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByTextHash = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { hash?: string; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByTextHash', async ({ hash, page }, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const currentHash = hash ?? selectLastHash(state)
    const lastPage = selectPageFetched(state)
    const nextPage = page === undefined ? (lastPage === undefined ? 1 : lastPage + 1) : page
    const filter = selectFiltersForAPI(state)
    const response = await axiosInstance.post('/api/discovery/search-by-text-hash', {
      hash: currentHash,
      page: nextPage,
      filter,
    })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchById = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { trackId: string | number; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchById', async ({ trackId, page = 1 }, thunkApi) => {
  try {
    const state = thunkApi.getState()
    const filter = selectFiltersForAPI(state)
    const appliedSearchParams = selectAppliedSearchParams(state)
    const hasUserInteractedWithSegment = selectUserInteractedWithSegment(state)
    const isRefined = appliedSearchParams !== null

    const response = await axiosInstance.post('/api/discovery/search-by-id', {
      trackId,
      page,
      time_offset:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeOffset')
          : undefined,
      time_limit:
        isRefined && hasUserInteractedWithSegment
          ? getSegmentRangeProperty(appliedSearchParams.segmentRange, 'timeLimit')
          : undefined,
      prioritise_bpm: isRefined ? appliedSearchParams.queryParams.prioritise_bpm : undefined,
      filter,
    })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

// Announcement: input value has max 2 words
export const searchByMetadata = createAsyncThunk<
  TrackListDetailedResponse,
  { text?: string; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByMetadata', async ({ text, page = 1 }, thunkApi) => {
  try {
    const filter = selectFiltersForAPI(thunkApi.getState())
    const response = await axiosInstance.post('/api/discovery/search-by-metadata', { name: text, page, filter })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const tagList = createAsyncThunk<
  TagListResponse,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  void,
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/tagList', async (_, thunkApi) => {
  try {
    const response = await axiosInstance.get('/api/discovery/tag-list')
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const searchByTag = createAsyncThunk<
  SimilarSearchDetailedResponse,
  { tagId: number; page?: number },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/searchByTag', async ({ tagId, page = 1 }, thunkApi) => {
  try {
    const filter = selectFiltersForAPI(thunkApi.getState())
    const response = await axiosInstance.post('/api/discovery/search-by-tag', { tagId, page, filter })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const search = createAsyncThunk<
  SearchResponse,
  { query: string; page?: number },
  {
    rejectValue: CustomError
    state: RootState
  }
>('discovery/search', async ({ query, page = 1 }, thunkApi) => {
  try {
    const filter = selectFiltersForAPI(thunkApi.getState())
    const response = await axiosInstance.post('/api/discovery/search', { query, page, filter })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      return thunkApi.rejectWithValue(response.data?.error ?? response.data)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const customError = parseErrorFromThunk(error)
    return thunkApi.rejectWithValue(customError)
  }
})

export const fetchFilterFieldValues = createAsyncThunk<
  { values: [{ value: string; tracks: number }] },
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  { field: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/fetchFilterFieldValues', async ({ field }, thunkApi) => {
  const userEmail = selectUserEmail(thunkApi.getState())
  try {
    const response = await axiosInstance.get(`/api/discovery/filter-field-values/${field}`) // ${user.apiHost}/v1/tracks/values/${field}
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    if (parserResponse.data.data.length === 0) {
      Sentry.captureMessage(`No values found for filter field: ${field}, user email: ${userEmail}`)
      void thunkApi.dispatch(
        addNotification({
          variant: NotificationStatus.WARNING,
          message: `An empty value-list was fetched for field ${field}`,
        }),
      )
      return
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    Sentry.captureMessage(`Failed to fetch filter field for: ${field}, user email: ${userEmail}`)
    console.error(`Failed to fetch filter field for: ${field}`)
    // void thunkApi.dispatch(
    //   addNotification({
    //     variant: NotificationStatus.ERROR,
    //     message: errors.OPERATION_FAILED_RETRY.message,
    //   }),
    // )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const autocomplete = createAsyncThunk<
  AutocompleteResponse,
  { query: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/autocomplete', async ({ query }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`/api/discovery/autocomplete`, { query })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const promptSuggestions = createAsyncThunk<
  AutocompleteResponse,
  { query: string },
  {
    rejectValue: SimilaritySearchAPIError
    state: RootState
  }
>('discovery/promptSuggestions', async ({ query }, thunkApi) => {
  try {
    const response = await axiosInstance.post(`/api/discovery/prompt-suggestions`, { query })
    const parserResponse = responseSchema.safeParse(response.data)
    if (!parserResponse.success || !parserResponse.data.success) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw thunkApi.rejectWithValue(response.data.error)
    }

    return parserResponse.data.data
  } catch (error: unknown) {
    void thunkApi.dispatch(
      addNotification({
        variant: NotificationStatus.ERROR,
        message: errors.OPERATION_FAILED_RETRY.message,
      }),
    )
    const parsedError = parseSimilaritySeachApiError(error)
    return thunkApi.rejectWithValue(parsedError)
  }
})

export const getTrackByClientId = createAsyncThunk<DetailedTrack, string, { rejectValue: SimilaritySearchAPIError }>(
  'discovery/getTrackByClientId',
  async (trackClientId, thunkApi) => {
    try {
      const response = await axiosInstance.post(`/api/discovery/track/getByClientId`, { id: trackClientId })
      const parserResponse = responseSchema.safeParse(response.data)
      if (!parserResponse.success || !parserResponse.data.success) {
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw thunkApi.rejectWithValue(response.data.error)
      }

      return parserResponse.data.data.track
    } catch (error: unknown) {
      const parsedError = parseSimilaritySeachApiError(error)
      return thunkApi.rejectWithValue(parsedError)
    }
  },
)
