import { useState, useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  selectFeatureFlags,
  selectFreeTrial,
  selectShouldBuySubscription,
  selectUserEmail,
  selectUserId,
} from '@/store/auth/selectors'
import CustomLoading from '../CustomLoading'
import { hasPublicRouteInPathname, publicRoutes } from '@/config/routes'
import { subscriptionStatus } from '@/store/auth/thunks'

const RouteGuard = ({ children }: { children: JSX.Element }) => {
  const router = useRouter()
  const [authorized, setAuthorized] = useState(false)
  const [loading, setLoading] = useState(false)
  const freeTrial = useAppSelector(selectFreeTrial)
  const shouldBuySubscription = useAppSelector(selectShouldBuySubscription)
  const userId = useAppSelector(selectUserId)
  const userEmail = useAppSelector(selectUserEmail)
  const featureFlags = useAppSelector(selectFeatureFlags)
  const dispatch = useAppDispatch()

  const getFeatureFlags = useCallback(
    async (path: string) => {
      if (featureFlags === null && !hasPublicRouteInPathname(path)) {
        setLoading(true)
        try {
          const subscriptionStatusResponse = await dispatch(subscriptionStatus()).unwrap()
          return subscriptionStatusResponse?.features
        } catch (e) {}
      }
      return featureFlags
    },
    [dispatch, featureFlags],
  )

  const authCheck = useCallback(
    async (url: string) => {
      const path = url.split('?')[0]

      if (
        (path.indexOf('/users') === 0 || path.indexOf('/track-parts') === 0) &&
        (userEmail === undefined || !userEmail.includes('aimsapi.com'))
      ) {
        setAuthorized(false)
        void router.push('/')
        return
      }

      if (shouldBuySubscription && path !== '/settings/plans' && path !== '/login') {
        setAuthorized(false)
        if (freeTrial.trialExpiresAt !== undefined) {
          void router.push('/settings/plans')
        }
        return
      }

      const currentFeatureFlags = await getFeatureFlags(path)

      const isCatalogSectionEnabled = currentFeatureFlags?.catalogSection ?? false
      const isDiscoverySectionEnabled = currentFeatureFlags?.discoverySection ?? false

      if ((path === '/search' || path === '/') && !isDiscoverySectionEnabled) {
        setAuthorized(false)
        void router.push(isCatalogSectionEnabled ? '/dashboard' : '/settings/plans')
        return
      }

      if ((path.indexOf('/batch') === 0 || path === '/dashboard') && !isCatalogSectionEnabled) {
        setAuthorized(false)
        void router.push(isDiscoverySectionEnabled ? '/' : '/settings/plans')
        return
      }

      if ((path === '/search' || path === '/') && !isDiscoverySectionEnabled) {
        setAuthorized(false)
        void router.push(isCatalogSectionEnabled ? '/dashboard' : '/settings/plans')
        return
      }

      if ((path.indexOf('/batch') === 0 || path === '/dashboard') && !isCatalogSectionEnabled) {
        setAuthorized(false)
        void router.push(isDiscoverySectionEnabled ? '/' : '/settings/plans')
        return
      }

      setAuthorized(true)
    },
    [freeTrial.trialExpiresAt, router, shouldBuySubscription, userEmail, getFeatureFlags],
  )

  useEffect(() => {
    void authCheck(router.asPath)

    const privateRouteCheck = (pathname: string) => {
      if (!publicRoutes.includes(pathname) && userId === '') {
        void router.push('/login')
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw 'navigation aborted'
      }
    }

    const handleRouteChangeStart = (pathname: string) => {
      privateRouteCheck(pathname)
      setAuthorized(!shouldBuySubscription)
      setLoading(true)
    }

    const handleRouteChangeComplete = () => {
      setLoading(false)
      void authCheck(router.asPath)
    }

    const handleHideLoader = () => {
      setLoading(false)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleHideLoader)

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleHideLoader)
    }
  }, [authCheck, router, shouldBuySubscription, userId])

  return authorized ? children : loading ? <CustomLoading fullWidth /> : null
}

export default RouteGuard
