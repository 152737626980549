import { ReactNode, useEffect, useLayoutEffect, useMemo } from 'react'
import { CssBaseline, ThemeProvider as MuiThemeProdiver, createTheme } from '@mui/material'
import { getThemeOptions } from '@/styles/theme'
import { useAppSelector } from '../../store/hooks'
import { selectIsDarkMode } from '../../store/api-user/selectors'
import { useRouter } from 'next/router'

// Announcement: see https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter()
  let isDarkModeEnabled =
    (useAppSelector(selectIsDarkMode) ?? typeof window !== 'undefined')
      ? window.localStorage.getItem('darkMode') === 'true'
      : false
  if (router.query.theme === 'dark') {
    isDarkModeEnabled = true
  }
  const theme = useMemo(() => createTheme(getThemeOptions(isDarkModeEnabled ? 'dark' : 'light')), [isDarkModeEnabled])

  useIsomorphicLayoutEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkModeEnabled ? 'dark' : 'light')
  }, [isDarkModeEnabled])

  return (
    <MuiThemeProdiver theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProdiver>
  )
}

export default ThemeProvider
