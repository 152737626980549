import AIMSLogoWithLabel from '@/public/logo-aims.svg'
import AIMSLogoSmall from '@/public/logo-small.svg'
import { selectFreeTrial, selectShouldBuySubscription } from '@/store/auth/selectors'
import { useAppSelector } from '@/store/hooks'
import { primary } from '@/styles/palettes/general'
import { useTheme } from '@mui/material/styles'
import Image from 'next/image'
import Link from 'next/link'

const style = { display: 'flex' }

const Logo = ({
  href,
  src,
  size,
  color,
  withLabel = false,
}: {
  href?: string
  src?: string
  size?: number
  color?: string
  withLabel?: boolean
}) => {
  const shouldBuySubscription = useAppSelector(selectShouldBuySubscription)
  const freeTrial = useAppSelector(selectFreeTrial)
  const theme = useTheme()
  const isLightMode = theme.palette.mode === 'light'

  const logo =
    src === undefined ? (
      withLabel ? (
        <AIMSLogoWithLabel
          style={{ color: color ?? (isLightMode ? primary.main : 'white') }}
          alt="AIMS"
          width={size ?? 92}
        />
      ) : (
        <AIMSLogoSmall
          style={{ color: color ?? (isLightMode ? primary.main : 'white') }}
          alt="AIMS"
          height={size ?? 24}
          width={size ?? 24}
        />
      )
    ) : (
      <Image src={src} alt="Logo" height={size ?? 24} width={size ?? 24} priority />
    )

  return shouldBuySubscription || freeTrial.trialExpiresAt === undefined || href === undefined ? (
    logo
  ) : (
    <Link style={style} href={href}>
      {logo}
    </Link>
  )
}

export default Logo
