import { DEFAULT_PAGE_SIZE } from '@/store/discovery/slice'
import { DetailedTrack } from '@/types/discovery/track'
import { v4 as uuidv4 } from 'uuid'

export type GenericTrack = DetailedTrack | NestedMasterTrack

interface NestedMasterTrack {
  masterTrack: DetailedTrack
  tracklist: DetailedTrack[]
}

/* eslint-disable @typescript-eslint/naming-convention */
const applyNestingToTrack = (track: DetailedTrack, versions: DetailedTrack[]): GenericTrack => {
  const nestedMasterTrack: NestedMasterTrack = {
    masterTrack: track,
    tracklist: versions,
  }

  return nestedMasterTrack.tracklist.length === 0 ? track : nestedMasterTrack
}

export const getTracksAsNested = (tracks: DetailedTrack[], pageDisplayed?: number): GenericTrack[] => {
  if (pageDisplayed === undefined) {
    return []
  }

  let tempTracks = [...tracks.slice(0, pageDisplayed * DEFAULT_PAGE_SIZE)]
  const nestedTracks: GenericTrack[] = []

  const groupsById: { [key: string]: DetailedTrack[] } = {}
  const indices: string[] = []

  // group by ID (master_track_id -> id_client)
  tempTracks.forEach((track: DetailedTrack, index: number): void => {
    const { album_code, master_track_id, master_track_number, id_client, id } = track
    const key = `${album_code ?? uuidv4()}:${master_track_id ?? master_track_number ?? id_client}`
    indices[index] = id.toString()
    if (!Array.isArray(groupsById[key])) {
      groupsById[key] = []
    }
    groupsById[key].push(track)
  })

  // remove groups with a single track
  const groupKeysToKeep = Object.keys(groupsById).filter((key) => groupsById[key].length > 1)
  const groups: { [key: string]: DetailedTrack[] } = {}
  groupKeysToKeep.forEach((key) => {
    groups[key] = groupsById[key]
  })

  // remove tracks that are already grouped
  tempTracks = tempTracks.filter(
    (track) =>
      Object.keys(groups).filter((key) => groups[key].filter((groupTrack) => groupTrack.id === track.id).length === 0)
        .length === Object.keys(groups).length,
  )

  // group by track number (master_track_number -> track_number)
  tempTracks.forEach((track: DetailedTrack): void => {
    const { album_code, master_track_number, track_number } = track
    const key = `${album_code ?? uuidv4()}:${master_track_number ?? track_number ?? uuidv4()}`
    if (!Array.isArray(groups[key])) {
      groups[key] = []
    }
    groups[key].push(track)
  })

  // sort and output tracks (sorting needs to be performed since two independent grouping attempts were made)
  indices.forEach((id: string): void => {
    const groupKeys = Object.keys(groups).filter(
      (key) => groups[key].filter((track) => track.id.toString() === id).length > 0,
    )
    if (groupKeys.length === 0) {
      return
    }
    const groupKey = groupKeys[0]
    const tracks = groups[groupKey].sort((trackA, trackB): number => {
      return indices.indexOf(trackA.id.toString()) > indices.indexOf(trackB.id.toString()) ? 1 : -1
    })
    const main = tracks.splice(0, 1)[0]
    const trackWithNestingApplied = applyNestingToTrack(main, tracks)
    nestedTracks.push(trackWithNestingApplied)
    delete groups[groupKey] // eslint-disable-line @typescript-eslint/no-dynamic-delete
  })

  return nestedTracks
}
