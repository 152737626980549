interface Route {
  path: string
  text: string
  isProtected: boolean
}

const routes: Route[] = [
  {
    path: '/dashboard',
    text: 'Dashboard',
    isProtected: true,
  },
  {
    path: '/settings/account',
    text: 'Settings',
    isProtected: true,
  },
  {
    path: '/settings/plans',
    text: 'Plans',
    isProtected: true,
  },
  {
    path: '/settings/custom-taxonomy',
    text: 'Custom taxonomy',
    isProtected: true,
  },
  {
    path: '/search',
    text: 'Search',
    isProtected: true,
  },
  {
    path: '/login',
    text: 'Login',
    isProtected: false,
  },
  {
    path: '/forgot-password',
    text: 'Forgot your password',
    isProtected: false,
  },
  {
    path: '/registration',
    text: 'Registration',
    isProtected: false,
  },
  {
    path: '/reset-password',
    text: 'Reset password',
    isProtected: false,
  },
  {
    path: '/terms',
    text: 'Terms and Conditions',
    isProtected: false,
  },
  { path: '/share/', text: 'Shared playlist', isProtected: false },
  { path: '/magic-login/', text: 'Magic login', isProtected: false },
  { path: '/playlist/', text: 'Playlist', isProtected: false },
  { path: '/embedded-playlist/', text: 'Embedded playlist', isProtected: false },
]

export const publicRoutes = routes.flatMap((route: Route) => {
  return route.isProtected ? [] : route.path
})

export const hasPublicRouteInPathname = (pathname: string) =>
  publicRoutes.some((route) => pathname.indexOf(route) === 0)
